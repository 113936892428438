@use "@shared/ui/styles/breakpoints";

.page {
  background-color: var(--color-background-muted);

  &-body {
    width: min(90%, var(--size-xl));
    height: auto;
    margin: 0 auto;
    padding-block: var(--spacing-7);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-4);
    text-align: center;

    @include breakpoints.md {
      gap: var(--spacing-7);
      padding-block: var(--spacing-6) var(--spacing-7);
    }
  }
}

.header {
  position: sticky;
  top: var(--size-9);
  padding-block: var(--spacing-3);

  @include breakpoints.md {
    background: transparent;
    padding-block: var(--spacing-7) 0;
  }

  &-description {
    font-size: var(--font-size-0);

    @include breakpoints.md {
      font-size: var(--font-size-1);
    }
  }
}
