@use "sass:map";

// Same as css vars
// --size-xxs: 240px;
// --size-xs: 360px;
// --size-sm: 480px;
// --size-md: 768px;
// --size-lg: 1024px;
// --size-xl: 1440px;
// --size-xxl: 1920px;

$bp-xxs: 240px;
$bp-xs: 360px;
$bp-sm: 480px;
$bp-md: 768px;
$bp-lg: 1024px;
$bp-xl: 1440px;
$bp-xxl: 1920px;

$breakpoints: (
  "xxs": $bp-xxs,
  "xs": $bp-xs,
  "sm": $bp-sm,
  "md": $bp-md,
  "lg": $bp-lg,
  "xl": $bp-xl,
  "xxl": $bp-xxl,
);

@mixin at($bp) {
  $breakpoint: map.get($breakpoints, $bp);

  @if $breakpoint == null {
    @error "Breakpoint #{$bp} does not exist.";
  }

  @media (min-width: #{$breakpoint}) {
    @content;
  }
}

@mixin under($bp) {
  $breakpoint: map.get($breakpoints, $bp);

  @if $breakpoint == null {
    @error "Breakpoint #{$bp} does not exist.";
  }

  @media (max-width: #{$breakpoint - 1px}) {
    @content;
  }
}

@mixin between($min, $max) {
  $bpMin: map.get($breakpoints, $min);
  $bpMax: map.get($breakpoints, $max);

  @if $bpMin == null {
    @error "Breakpoint #{$min} does not exist.";
  }

  @if $bpMax == null {
    @error "Breakpoint #{$max} does not exist.";
  }

  @media (min-width: #{$bpMin}) and (max-width: #{$bpMax - 1px}) {
    @content;
  }
}

@mixin xxs {
  @include at(xxs) {
    @content;
  }
}
@mixin xs {
  @include at(xs) {
    @content;
  }
}
@mixin sm {
  @include at(sm) {
    @content;
  }
}
@mixin md {
  @include at(md) {
    @content;
  }
}
@mixin lg {
  @include at(lg) {
    @content;
  }
}
@mixin xl {
  @include at(xl) {
    @content;
  }
}
@mixin xxl {
  @include at(xxl) {
    @content;
  }
}
